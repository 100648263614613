import {numberWithCommas} from "../../common";
import React, {useEffect, useMemo, useState} from "react";
import DailyFullReportExpRow, {ExpenceType} from "./DailyFullReportExpRow";

interface Props {
    exp_type: "bank" | "cash";
    formValues: Map<string, any>;
    setFormValues: (fv: Map<string, any>) => void;
    exp_types: Array<ExpenceType>,
}

const DailyFullReportExpences = ({
                                     exp_type,
                                     formValues,
                                     setFormValues,
                                     exp_types,
                                 }: Props) => {
    const [fv, setFV] = useState(formValues);
    useEffect(() => {
        // formValues.forEach(
        //     (value, key) => {
        //         if (!key.includes(exp_type)) {
        //             fv.set(key, value);
        //         }
        //     }
        // );
        setFV(formValues);
    }, [formValues]);

    // const calculateBankTotal = (formValues: Map<string, any>) => {
    //     let total = 0;
    //     for (let i = 0; i < 50; i++) {
    //         let value = formValues.get(`${exp_type}_expense_amount_${i}`);
    //         if (value) {
    //             total += value * 1;
    //         }
    //     }
    //     total += formValues.get(`${exp_type}_palet_price`) * 1;
    //     return numberWithCommas(total.toFixed(2)) + ' лв.'
    // }

    const removeRow = (idx: number) => {
        const newFV = new Map(fv);

        newFV.set(`${exp_type}_expense_description_${idx}`, "");
        newFV.set(`${exp_type}_expense_qty_${idx}`, 0);
        newFV.set(`${exp_type}_expense_amount_${idx}`, 0);

        for (let i = idx; i < 50; i++) {
            newFV.set(`${exp_type}_expense_description_${i}`, fv.get(`${exp_type}_expense_description_${i + 1}`));
            newFV.set(`${exp_type}_expense_qty_${i}`, fv.get(`${exp_type}_expense_qty_${i + 1}`));
            newFV.set(`${exp_type}_expense_amount_${i}`, fv.get(`${exp_type}_expense_amount_${i + 1}`));
        }

        for (let i = 0; i < 50; i++) {
            if (!newFV.get(`${exp_type}_expense_description_${i}`)
                && !newFV.get(`${exp_type}_expense_qty_${i}`)
                && !newFV.get(`${exp_type}_expense_amount_${i}`)) {
                newFV.delete(`${exp_type}_expense_description_${i}`);
                newFV.delete(`${exp_type}_expense_qty_${i}`);
                newFV.delete(`${exp_type}_expense_amount_${i}`);
            }
        }
        return newFV;
    }

    const updateFormValues = (fv: Map<string, any>) => {
        setFV(fv);
        setFormValues(fv);
    }

    const generateBankExpRow = (idx: number) => {
        return <DailyFullReportExpRow
            key={`${exp_type}_bank_exp_${idx}`}
            exp_types={exp_types}
            record={{
                recordType: "exp",
                description: fv.get(`${exp_type}_expense_description_${idx}`),
                qty: fv.get(`${exp_type}_expense_qty_${idx}`),
                price: fv.get(`${exp_type}_expense_amount_${idx}`),
            }}
            onchange={(record) => {
                const newFV = new Map(fv);
                newFV.set(`${exp_type}_expense_description_${idx}`, record.description);
                newFV.set(`${exp_type}_expense_qty_${idx}`, record.qty);
                newFV.set(`${exp_type}_expense_amount_${idx}`, record.price);
                updateFormValues(newFV);
            }}
            onRemove={() => {
                console.log("remove: ", idx);
                const newFV = removeRow(idx);
                updateFormValues(newFV);
            }}
        />
    }

    const bankExpRows = useMemo(() => {
        const bankExpRows = [];
        for (let idx = 0; idx < 50; idx++) {
            if (fv.get(`${exp_type}_expense_description_${idx}`)) {
                bankExpRows.push(generateBankExpRow(idx));
            }
        }
        bankExpRows.push(generateBankExpRow(bankExpRows.length));
        return bankExpRows;
    }, [fv]);

    return (

        <table className={"table table-sm table-bordered "}>
            <tr className={"text-center"}>
                <td className={"w-480px"}><strong>Описание</strong></td>
                <td className={"w-75px"}><strong>К-во</strong></td>
                <td className={"w-75px"}><strong>Сума(лв)</strong></td>
                <td className={"w-35px text-end"}></td>
            </tr>
            {
                bankExpRows
            }
        </table>
        //
        //         [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14].map(
        //             idx => <tr key={"bank_exp_" + idx}>
        //                 <td className={"no-padding w-480px"}>
        //
        //                 </td>
        //                 <td className={"no-padding"}>
        //
        //                 </td>
        //             </tr>
        //         )
        //     }
        //     <tr>
        //         <td className={"no-padding w-480px"}>
        //             <Row>
        //                 <Col>
        //                     <input
        //                         className={"form-control form-control-sm description w-100"}
        //                         type={"text"} readOnly={true}
        //                         value={"РАЗХОД ЗА ПАЛЕТИ"}
        //                     />
        //                 </Col>
        //                 <Col sm={"auto"} className={"text-end pt-2"}>
        //                     Брой:
        //                 </Col>
        //                 <Col sm={2} className={"ps-0 ms-0 small"}>
        //                     <input
        //                         className={"text-end form-control form-control-sm"} type={"text"}
        //                         name={"bank_palet_qty"} onChange={this.handleFormChange}
        //                         value={zeroToEmptyString(this.state.formValues.get("bank_palet_qty"))}
        //                     />
        //                 </Col>
        //             </Row>
        //         </td>
        //         <td className={"no-padding"}>
        //             <input
        //                 className={"text-end form-control form-control-sm"} type={"text"}
        //                 name={"bank_palet_price"} onChange={this.handleFormChange}
        //                 value={zeroToEmptyString(this.state.formValues.get("bank_palet_price"))}
        //             />
        //         </td>
        //     </tr>
        //     <tr className={"text-end"}>
        //         <th className={"w-480px"}><strong>Всичко</strong></th>
        //         <th className={"w-120px"}><strong>{calculateBankTotal()}</strong></th>
        //     </tr>

        // </table>
    )
}

export default DailyFullReportExpences
