import React from "react";
import {PropertyValue, Sale, SALE_TYPE, SaleItem} from "../../types";
import {bagTypeTitle} from "../../common";

export interface ExpNotesListProps {
    type: string;
    reports: any[];
    peletTypes: PropertyValue[];
    brickTypes: PropertyValue[];
    bagTypes: PropertyValue[];
}

export function ExpNotesList(props: ExpNotesListProps) {

    const renderOthersDataRow = function (m: Sale) {
        const rd = JSON.parse(m.request_data || '');
        const items: any[] = [];
        for(let idx=0; idx<5; idx++){
            let total = rd['others_total_' + idx] * 1;
            if(!isNaN(total) && total > 0) {
                items.push({
                    name: rd['others_' + idx] || '',
                    me: rd['others_me_' + idx] || '',
                    qty: rd['others_qty_' + idx] * 1,
                    price: rd['others_price_' + idx] * 1,
                    total: total
                });
            }
        }
        return items.map(
            (i, idx) => {
                return idx === 0 ?
                    <tr>
                        <td rowSpan={items.length} className={"w-100px"}>{m.sales_number?.toString()}&nbsp;</td>
                        <td rowSpan={items.length}>{m.client} -&gt; {m.client_city}</td>
                        <td>{i.name}</td>
                        <td className={"text-center"}>{i.me}</td>
                        <td className={"text-end"}>{i.qty}&nbsp;</td>
                        <td className={"text-end"}>{i.price.toFixed(2)}&nbsp;</td>
                        <td className={"text-end"}>{i.total.toFixed(2)}&nbsp;</td>
                        <td rowSpan={items.length} className={"text-end"}>{m.inv_number}&nbsp;</td>
                        <td rowSpan={items.length} className={"text-center"}>ДРУГИ&nbsp;</td>
                        <td rowSpan={items.length} className={"text-end"}>{m.total_price}&nbsp;</td>
                    </tr>
                    :
                    <tr>
                        <td>{i.name}</td>
                        <td className={"text-center"}>{i.me}</td>
                        <td className={"text-end"}>{i.qty}&nbsp;</td>
                        <td className={"text-end"}>{i.price.toFixed(2)}&nbsp;</td>
                        <td className={"text-end"}>{i.total.toFixed(2)}&nbsp;</td>
                    </tr>

            }
        )
    }

    const renderBrickDataRow = function (m: Sale) {
        const calcPaleti = function () {
            let total = 0;
            m.items?.forEach(i => total += i.palet_qty);
            return total;
        }

        return m.items?.map(
            (i, idx) => {
                return idx === 0 ?
                    <tr>
                        <td rowSpan={m.items?.length} className={"w-100px"}>{m.sales_number?.toString()}&nbsp;</td>
                        <td rowSpan={m.items?.length}>{m.client} -&gt; {m.client_city}</td>
                        <td rowSpan={m.items?.length} className={"text-end"}>{calcPaleti()}&nbsp;</td>
                        <td rowSpan={m.items?.length} className={"text-end"}>{m.returned}&nbsp;</td>
                        <td>{props.brickTypes[i.property_id].description.shortName}</td>
                        <td className={"text-end"}>{i.palet_qty * i.qty_in_palet}&nbsp;</td>
                        <td rowSpan={m.items?.length} className={"text-end"}>{m.price}&nbsp;</td>
                        <td rowSpan={m.items?.length} className={"text-end"}>{m.inv_number}&nbsp;</td>
                        <td rowSpan={m.items?.length} className={"text-start"}>{
                            m.sale_type === SALE_TYPE.others ? "ДРУГИ" :
                                (m.sale_type?.endsWith("wo_env") ? 'КЕШ' : 'БАНКА')
                        }&nbsp;</td>
                        <td rowSpan={m.items?.length} className={"text-end"}>{m.total_price}&nbsp;</td>
                    </tr>
                    :
                    <tr>
                        <td>{props.brickTypes[i.property_id].description.shortName}</td>
                        <td className={"text-end"}>{i.palet_qty * i.qty_in_palet}&nbsp;</td>
                    </tr>

            }
        )
    }

    const renderPeletiDataRow = function (m: Sale) {

        if (m.items?.length === 0) {
            const request = JSON.parse(m.request_data || '');
            const keys = Object.keys(request);
            const values = Object.values(request);

            for (let i = 0; i < 5; i++) {
                if (keys.indexOf('bag_type_' + i) > -1) {
                    const bag_type = values[keys.indexOf('bag_type_' + i)];
                    const bag_qty = values[keys.indexOf('bag_qty_' + i)];
                    const item = values[keys.indexOf('pelet_type_' + i)];
                    const total = values[keys.indexOf('total_pelet_' + i)];
                    m.items.push(
                        {
                            bag_type: bag_type,
                            palet_qty: bag_qty,
                            qty_in_palet: total,
                            property_id: item,
                        } as SaleItem
                    )
                }
            }
        }

        const calcPaleti = function () {
            let total = 0;
            m.items?.forEach(i => total += i.bag_type * 1 === 2 ? i.palet_qty * 1 : 0);
            return total;
        }

        const getBackWeight = function (pelet_type: number, bagType: number) {
            if(!pelet_type || !bagType) return 0;

            const bag = props.bagTypes[bagType];
            const pelet = props.peletTypes[pelet_type];
            const ft: string = bag.description.peletPropertyName;
            // @ts-ignore
            return pelet.description[ft]*1;
        }

        return (<>
            {
                m.items?.map(
                    (i, idx) => {

                        return idx === 0 ?
                            <tr key={idx}>
                                <td rowSpan={m.items?.length}
                                    className={"w-100px"}>{m.sales_number?.toString()}&nbsp;</td>
                                <td rowSpan={m.items?.length}
                                    className={"w-240px"}>{m.client} -&gt; {m.client_city}</td>
                                <td>{props.peletTypes[i.pelet_type]?.value}</td>
                                <td>{bagTypeTitle(i.bag_type)}</td>
                                <td className={"text-end"}>{i.bag_qty}&nbsp;</td>
                                <td className={"text-end"}>{i.bag_qty * getBackWeight(i.pelet_type, i.bag_type)}</td>
                                <td rowSpan={m.items?.length} className={"text-end"}>{m.price}&nbsp;</td>
                                <td rowSpan={m.items?.length} className={"text-end"}>{m.inv_number}&nbsp;</td>
                                <td rowSpan={m.items?.length} className={"text-start"}>{
                                    m.sale_type === SALE_TYPE.others ? "ДРУГИ" :
                                        (m.sale_type?.endsWith("wo_env") ? 'КЕШ' : 'БАНКА')
                                }&nbsp;</td>
                                <td rowSpan={m.items?.length} className={"text-end"}>{m.total_price}&nbsp;</td>
                            </tr>
                            :
                            <tr key={idx}>
                                <td>{props.peletTypes[i.pelet_type]?.value}</td>
                                <td>{bagTypeTitle(i.bag_type)}</td>
                                <td className={"text-end"}>{i.bag_qty}&nbsp;</td>
                                <td className={"text-end"}>{i.bag_qty * getBackWeight(i.pelet_type, i.bag_type)}</td>
                            </tr>

                    }
                )
            }
        </>)
    }

    const renderDataRow = function (m: Sale, type: string) {
        if (type === 'others') {
            // @ts-ignore
            return (m.sale_type == "others") ? renderOthersDataRow(m) : null;
        }
        if (type === 'bricks') {
            // @ts-ignore
            return (m.sale_type == "bricks_w_env" || m.sale_type == "bricks_wo_env") ? renderBrickDataRow(m) : null;
        }
        if (type === 'peleti') {
            // @ts-ignore
            return (m.sale_type == "peleti_w_env" || m.sale_type == "peleti_wo_env") ? renderPeletiDataRow(m) : null;
        }
    }

    return (
        <>
            {
                props.reports.map(
                    m => [renderDataRow(m, props.type)]
                )
            }
        </>
    )
}
