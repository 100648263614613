import React from "react";
import {AccountOperation, OPERATION_TYPE, PAY_TYPE} from "../../types";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {stringToDateFormatOrNull} from "../../common";

export interface EditClientAccountDetailsProps {
    operations: AccountOperation[];
    balance: number;
    onAddItem?: () => any;
    onEditItem?: (item: AccountOperation) => any;
    onRemoveItem?: (item: AccountOperation) => any;
    onEditSale?: (saleId: number) => any;
}

export interface EditClientAccountDetailsState {
}

export class EditClientAccountDetails extends React.Component<EditClientAccountDetailsProps, EditClientAccountDetailsState> {

    renderAccountRows() {
        return this.props.operations.length == 0 ?
            <tr>
                <td colSpan={3}><strong>Няма записи</strong></td>
            </tr>
            :
            this.props.operations
                .filter(item => item.timestamp)
                .sort(
                (a: AccountOperation, b: AccountOperation) => {
                    return stringToDateFormatOrNull(b.timestamp, 'dd.mm.yyyy')!.getTime() - stringToDateFormatOrNull(a.timestamp, 'dd.mm.yyyy')!.getTime();
                }
            ).map(
                (item: AccountOperation, idx: number) => <tr key={idx}>
                    <td>{item.timestamp}</td>
                    <td>
                        {
                            this.props.onEditSale && item.sales_id ?
                                <a href={"#"} onClick={(e) => {
                                    e.preventDefault();
                                    if(this.props.onEditSale) this.props.onEditSale(item.sales_id)
                                }}>
                                    {item.description}
                                </a> : item.description
                        }
                    </td>
                    <td className={"text-end text-nowrap " + (item.operation === OPERATION_TYPE.WITHDRAW ? 'text-danger' : 'text-primary')}>
                        {(item.amount * (item.operation === OPERATION_TYPE.WITHDRAW ? -1 : 1)).toFixed(2)} лв
                    </td>
                    <td className={"text-end"}>
                        <span className="btn btn-sm btn-primary btn-light" onClick={() => this.props.onEditItem ? this.props.onEditItem(item) : {}}>
                            {
                                item.sales_id ? <FontAwesomeIcon icon="eye"/> :
                                    <FontAwesomeIcon icon="pen"/>
                            }
                        </span>
                    </td>
                </tr>
            );
    }

    render() {
        return (
            <div className={"col align-content-between"}>
                <div className="row">
                    <div className="col-12 mr-0 pr-0">
                        <div className={"row w-100"}>
                            <div className="col-6"><label>БАЛАНС:</label></div>
                            <div className={"col-6 text-end font-weight-bold text-light pt-1 " + (this.props.balance < 0 ? "bg-danger" : "bg-success")}>
                                {Number(this.props.balance).toFixed(2)} лв
                            </div>
                        </div>

                        <div className={"row d-block w-100 col-12 m-0 p-0"} style={{maxHeight: "60vh", overflow: "auto"}}>
                            <table className={"table table-sm m-0 p-0"}>
                                <thead>
                                <tr>
                                    <th>Дата</th>
                                    <th>Описание</th>
                                    <th className={"text-end"}>Сума</th>
                                    <th className={"text-end w-50px"}></th>
                                </tr>
                                </thead>
                                <tbody>
                                {this.renderAccountRows()}

                                {
                                    this.props.onAddItem &&
                                    <tr>
                                        <td colSpan={4}>
                                            <button className={"btn btn-outline-primary w-100 font-weight-bold"}
                                                    onClick={() => this.props.onAddItem ? this.props.onAddItem() : {}}
                                            >
                                                Добави нов запис
                                            </button>
                                        </td>
                                    </tr>
                                }
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
